g[transform^="translate(0,"]{
    opacity: 0.15;
} 

g[transform^="translate(280,"]{
    opacity: 0.15;
}

g[transform^="translate(942,"]{
    opacity: 0.15;
}    