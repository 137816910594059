/* css for hover on timebar */
.progress-time .timebar-item {
  opacity: 0;
  position: relative;
  top: -30px;
  padding: 6px;
  background-color: #a6f3b369;
  transition: 0.3s;
  font-size: 13px;
  padding-inline: 9px;
  border-radius: 25px;
}

.progress-time:hover .timebar-item {
  opacity: 0;
}

.cycle-time .timebar-item {
  opacity: 0;
  position: relative;
  top: -30px;
  padding: 6px;
  background-color: #a6e7f369;
  transition: 0.3s;
  font-size: 13px;
  padding-inline: 9px;
  border-radius: 25px;
}

.cycle-time:hover .timebar-item {
  opacity: 0;
}

.cool-down-time .timebar-item {
  opacity: 0;
  position: relative;
  top: -30px;
  padding: 6px;
  background-color: #cfcfcf69;
  transition: 0.3s;
  font-size: 13px;
  padding-inline: 9px;
  border-radius: 25px;
}

.cool-down-time:hover .timebar-item {
  opacity: 0;
}